@media all and (min-width: 992px) {

    .navbar .has-megamenu {
        position: static !important;
    }

    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 0;
    }

}
@media all and (max-width: 1080px) {

 .header_main .shop-btn{
    width: 100%;
    margin-top: 0;
 }

}

/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px) {

    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }

    .navbar-dark .navbar-toggler {
        border-color: #eeeeee;
        color: #bfbcbc;
        font-size: 30px;
    }

    .header_main .menu-wrapper.show {
        left: 0;
        animation: move-sidebar 1s cubic-bezier(.01, .87, .36, .99);
    }
    .shop-btn {
        margin-top: 15px;
        width: 100%;
    }
}




@media(max-width:767px) {

    .header_main .shop-btn{
        margin-top: 15px;
     }
    .serch-box {
        margin-top: 15px;
        display: block;
    }

    .searching {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }

    .search-icon {
        right: 20px;
    }

    .category {
        /* margin-top: 15px; */
        width: 100%;    
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        margin-left: 0px;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .shop-btn {
        margin-top: 15px;
        width: 100%;
    }

    .top-header {
        display: none;
    }

    .logo-content {
        padding-left: 15px;
    }

    .bars {
        top: 25px;
    }

    .navbar-dark .navbar-toggler {
        border-color: #eeeeee;
        color: #bfbcbc;
        font-size: 30px;
    }
    .header_main .menu-wrapper{
        width: 300px;
    }

}