.ambiente_banner img {
    width: 100%;    
}
.ambiente_title h1 {
    font-size: 34px;
    border-right: #1595D3;
    color: #455560;
    line-height: 44px;
    margin: 0;
}
.ambiente_content {
    margin-top: 100px;
}
.ambiente_title {
    margin-bottom: 77px;
    padding-left: 20px;
    border-left: 2px solid #1595D3;
}
.tracciabilita_page_main {
    display: flex;
    width: 100%;
    column-gap: 88px;
    align-items: center;
    color: #455560;
}
.tracciabilita_left {
    flex: 1;
}
.tracciabilita_right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tracciabilita_main {
    width: 100%;
    max-width: 420px;
}
.tracciabilita_title h4 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 15px;
}
.tracciabilita_title {
    border-bottom: 2px solid #1595D3;
}
p.filmar_promuove {
    margin-bottom: 40px;
    margin-top: 20px;
    line-height: 24px;
}
p.una_componente , p.filmar_prime {
    line-height: 24px;
}
.tracciabilita_main {
    font-weight: 400;
    font-size: 16px;
}
.tracciabilita_page_main.secound{
    margin-top: 100px;
}
.secound .tracciabilita_title h4 {
    max-width: 275px;
    width: 100%;
}
.tracciabilita_page_main.secound {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 100px;
}

p.filmar_prime {
    margin-top: 64px;
}
.tracciabilita_left img {
    max-width: 100%;
}
@media screen and (max-width: 1024px) {
    .tracciabilita_page_main {
        column-gap: 36px;
    }
    .ambiente_content {
        margin-top: 60px;
    }
    .ambiente_title {
        margin-bottom: 60px;
    }
    p.filmar_prime {
        margin-top: 10px;
    }
    p.Filmar_promuove {
        margin-bottom: 10px;
    }
    .tracciabilita_page_main.secound {
        margin-top: 50px;
    }
    .tracciabilita_main {
        font-size: 14px;
    }
    .tracciabilita_title h4 {
        font-size: 20px;
    }
}
@media screen and (max-width: 768px) {
    .tracciabilita_page_main {
        flex-direction: column;
    }
    .tracciabilita_page_main.secound {
        flex-direction: column-reverse;
    }
    
    .tracciabilita_main {
        width: 100%;
        max-width: 100%;
        margin-top: 40px;
    }
    .tracciabilita_left img {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
    .tracciabilita_left {
        width: 100%;
    }
    .ambiente_title {
        margin-bottom: 40px;
    }
    .ambiente_title h1{
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
    }
    .tracciabilita_title h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    p.filmar_promuove , p.una_componente {
        margin-bottom: 40px;  
    }
    .tracciabilita_main{    
        font-size: 16px; 
    }
    .tracciabilita_page_main.secound {
        margin-top: 20px;
        padding-bottom: 60px;
    }
    p.filmar_prime {
        margin-bottom: 0;
    }
    .scopri_anche_section {
        border-top: 1px solid var(--Light-Grey);
    }
}
