button {
    background-color: var(--Independence);
    color: var(--white);
    border-radius: 12px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
}

.banner_img {
    margin-bottom: 120px;
}

.banner_img img {
    width: 100%;
}

.ethica_section {
    margin-bottom: 100px;
}

.etica_title h2 {
    font-size: 34px;
    line-height: 44px;
    width: 100%;
    padding-left: 20px;
    margin: 0px;
    font-weight: 700;
    border-left: 2px solid var(--light-blue);
    margin-bottom: 37px;
}

.filmar_text {
    display: flex;
    column-gap: 104px;
}

.filmar_text .filmar_text_col {
    flex: 1;
}

.filmar_text .filmar_text_col p {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
}

.codice_etico_section {
    margin-bottom: 100px;
}

.codice_etico_section .filmar_text_col p {
    margin-bottom: 40px;
    max-width: 620px;
}

.codice_etico_title h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin: 0px 0px 38px;
}


@media only screen and (max-width:1024px) {
    .filmar_text {
        column-gap: 40px;
    }
}

@media only screen and (max-width:767px) {
    .filmar_text {
        display: block;
    }

    .banner_img,
    .codice_etico_section,
    .ethica_section {
        margin-bottom: 60px;
    }

    .filmar_text .filmar_text_col p {
        margin-bottom: 40px;
    }

    .codice_etico_title h3 {
        margin-bottom: 40px;
    }

    .etica_title h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
    }
}