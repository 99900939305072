.blog_page .main_title h2 {
    margin: 60px 0 23px;
    font-size: 34px;
    line-height: 44px;
    max-width: 251px;
}

.blog_page .scopri_anche_section {
    margin-bottom: 0;
    border-top: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--Light-Grey);
    margin-bottom: 40px;
}

.blog_page .scopri_anche_item {
    padding-bottom: 0;
}


.blog_page .scopri_anche_section .year_dropdown {
    position: relative;
    width: 100%;
    max-width: 116px;
    margin-left: auto;
}

.blog_page .year_dropdown a.year_current {
    display: block;
    border-radius: 10px;
    padding: 10px 15px;
    background: #f5f5f5;
    color: #455560;
    text-decoration: none;
    position: relative;
}

.blog_page .year_dropdown a.year_current:after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    right: 15px;
    font-size: 20px;
}

.blog_page .year_dropdown .year_options {
    /* display: none; */
    z-index: 9;
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    background: #f5f5f5;
    border-radius: 10px;
}

.blog_page .year_dropdown .year_options ul,.blog_year_select {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.blog_page .year_dropdown .year_options ul li {
    padding: 10px;
}

.blog_page .year_dropdown .year_options ul li a {
    text-decoration: none;
    color: #455560;
}

.blog_page .scopri_anche_box {
    background-color: #fff;
    border: 1px solid var(--Light-Grey);
}

.blog_page .scopri_anche_box.active {
    background-color: #455560;
}

.blog_page .scopri_anche_box.active a {
    color: #fff;
}

.blog_page .news_item {
    margin: 0;
    display: grid;
    row-gap: 40px;
    grid-template-rows: 1fr auto;
    margin-bottom: 0;
    break-inside: avoid;
    line-height: 0;
}

.blog_page .news_item a.news_wrapper {
    position: relative;
}

.blog_page .news_item a.news_wrapper img {
    width: 100%;
}

.blog_page .blog_wrapper {
    column-count: 3;
    column-gap: 40px;
}

.blog_page .news_data {
    position: absolute;
    bottom: 25px;
    left: 25px;
    color: #fff;
}

.blog_page .news_data p span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.blog_page .news_data p span.date {
    padding-right: 15px;
    font-weight: 400;
}

.blog_page .news_data p {
    margin: 0;
    margin-bottom: 5px;
}

.blog_page .news_data p.name {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 0;
}

.blog_page .more_section {
    display: flex;
    justify-content: center;
    padding: 40px 0 80px;
}

.blog_page .more_section .link a {
    background: #ffffff;
    padding: 12px 24px;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    border-radius: 12px;
    display: block;
    color: #455560;
    border: 1px solid var(--Light-Grey);
}

/* News Details */
.blog_detail .title_section {
    padding: 60px 0 40px;
}

.blog_detail .title_tag span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    display: block;
    margin-bottom: 8px;
}

.blog_detail .main_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog_detail .main_title h2 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    margin: 0;
}

.blog_detail .main_title .link a {
    background: #F5F5F5;
    padding: 12px 24px;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    border-radius: 12px;
    display: block;
    color: #455560;
}

.blog_detail .image {
    line-height: 0;
    margin-bottom: 60px;
}

.blog_detail .image img {
    max-width: 100%;
}

.blog_detail .content {
    margin-bottom: 60px;
}

.blog_detail .content p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 20px;
}

.blog_detail .slider_section .link a {
    background: #455560;
    padding: 12px 24px;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    border-radius: 12px;
    display: inline-block;
    margin-bottom: 60px;
    color: #ffffff;
}

.blog_detail .social_section .image {
    margin-bottom: 30px;
}

.blog_detail .social_icon ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    column-gap: 15px;
    justify-content: flex-end;
    margin: 0;
}

.blog_detail .social_icon ul li a {
    text-decoration: none;
}

.blog_detail .social_icon ul li a:after {
    font-family: 'FontAwesome';
    color: #455560;
    font-size: 22px;
}

.blog_detail .social_icon ul a.facebook:after {
    content: "\f09a";
}

.blog_detail .social_icon ul a.twitter:after {
    content: "\f099";
}

.blog_detail .social_icon ul a.linkdin:after {
    content: "\f0e1";
}

/* News Section */
.blog_detail .news_section .news_wrapper {
    margin: 100px 0;
}

.blog_detail .news_section .title h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 40px;
    color: #455560;
}

.blog_detail .news_section .news_content {
    display: flex;
    column-gap: 20px;
}

.blog_detail .news_section .news_content .news_item {
    flex: 1;
    position: relative;
}

.blog_detail .news_section .news_item img {
    width: 100%;
}

.blog_detail .news_section .news_data {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: #ffffff;
}

.blog_detail .news_section .news_data p {
    margin: 0;
}

.blog_detail .news_section .news_data p span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.blog_detail .news_section .news_data p span.date {
    padding-right: 15px;
    font-weight: 400;
}

.blog_detail .news_section .news_data p.name {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

/* Brand Page */
.blog_detail.brand .content {
    margin-bottom: 30px;
}

/* Metacat Page */
.blog_detail.metacat .iframe_container {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 60px;
}

.blog_detail.metacat .iframe_container iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog_detail.metacat .social_section .image {
    display: flex;
    column-gap: 47px;
}



@media only screen and (max-width: 1024px) {
    .blog_page .blog_wrapper {
        column-count: 2;
        column-gap: 20px;
    }

    .blog_page .news_item {
        row-gap: 20px;
    }

    .blog_page .scopri_anche_section {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .blog_page .scopri_anche_section .year_dropdown {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .blog_page .main_title h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 40px 0 30px;
        max-width: 180px;
    }

    .blog_page .blog_wrapper {
        column-count: 1;
        column-gap: 20px;
    }

    .blog_page .scopri_anche_section {
        padding-bottom: 0;
        border: 0;
        margin-bottom: 20px;
        row-gap: 30px;
    }

    /* Blog Details */
    .blog_detail .title_section {
        padding: 40px 0;
    }

    .blog_detail .main_title {
        flex-wrap: wrap;
    }

    .blog_detail .main_title h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    .blog_detail .image,
    .blog_detail .content {
        margin-bottom: 40px;
    }

    .blog_detail .social_icon ul {
        justify-content: flex-start;
    }

    .blog_detail .news_section .title h3 {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 24px;
    }

    .blog_detail .news_section .news_content {
        flex-wrap: wrap;
        row-gap: 20px;
        padding-bottom: 50px;
    }

    .blog_detail .news_section .news_content .news_item {
        flex: 0 0 100%;
    }

    .blog_detail .news_section .news_wrapper {
        margin: 60px 0 100px;
    }

    /*  metacat Page  */
    .blog_detail.metacat .iframe_container {
        margin-bottom: 40px;
    }

    .blog_detail.metacat .social_section .image {
        flex-wrap: wrap;
        row-gap: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .blog_page .news_item a.news_wrapper img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
    }
}