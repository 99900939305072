
.footer_main .container{
    max-width: 92% !important;
}
    .footer{
    /* position: absolute; */
    /* top: 100%; */
  
    padding: 30px 0px 10px 0px;
    background-color: #F9F9F9;
    /* width: 100vw; */
    margin-top: 50px;
}
.footer .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start ;
}
.footer .bait {
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    color: #224151;
    font-family: 'Bebas Neue', cursive !important;
    margin: 0px;
}
.footer .whole {
    font-size: 13px;
    font-weight: 400;
    color: #224151;
    margin: 0px;
}
.footer .footer-p {
    padding: 19px 0px 41px 0px;
    margin: 0px;
    max-width: 423px;
    color: rgba(145,145,145,1);
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
}
.footer .know-more{
    list-style: none;
    padding: 0px;
}
.footer .know-more h2{
   font-size: 20px;
   font-weight: 500;
   color: #1C1C1E;
}
.footer .know-more li + li{
    margin-top: 10px;
}
.footer .know-more li a{
    color: rgba(145,145,145,1);
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
}
.footer .social-icon i{
    margin-right: 15px;
    border: 1px solid;
    color: #224151;
}
.footer .facebook{
    border-radius: 50%;
    padding: 7px 10px;
}
.footer .twitter {
    border-radius: 50%;
    padding: 7px 8px;
}
 
.footer .you {
    border-radius: 50%;
    padding: 7px 7px;
}
.footer .instagram {
    border-radius: 50%;
    padding: 7px 9px;
}
.footer .copy {
    display: flex;
    justify-content: center;
    padding-top: 18px;
}
.footer .copy-right{
    font-size: 14px;
    font-weight: 700;
    color: #1C1C1E;
}
.footer .copy a{
    font-size: 14px;
    font-weight: 700;
    color: #1c1c1c;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
}
.footer .copy a:hover {
    color: #8886ef;
    text-decoration: underline;
}

.footer_menu_title{
    color: rgba(28,28,30,1);
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 15px 0;
}

.logo-content {
    padding-left: 9px;
}
