body {
    padding: 0px;
    margin: 0px;
    background: #fff;
    font-family: 'Heebo', cursive !important;
}

.header_main .container {
    max-width: 92% !important;
}

.header_main .top-header {
    background: #F8F8F8;
    padding: 12px 0px 8px 0px;
}

.header_main .middle-header {
    padding: 15px 0px;
    border-bottom: 2px solid #EFEFEF;
}

.header_main .Welcome {
    font-size: 13px;
    font-weight: 400;
    color: #3C485E;
    padding-right: 21px;
}

.header_main .hedaer-phone {
    text-decoration: none;
    color: #3C485E !important;
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
    text-align: left;
}

.header_main .phone-icon {
    padding-right: 5px;
}

.header_main .menu-wrapper {
    background-color: #fff;
    position: fixed;
    top: 0;
    display: block;
    left: -400px;
    width: 400px;
    padding: 100px 20px 20px 20px;
    box-shadow: 0px 0px 3px -1px #303030;
    z-index: 1000000;
    height: 100vh;
    transition: all 0.4s ease;
    overflow: auto;
}

.header_main .menu-wrapper.show {
    left: 0;
}

.menu-icon-wrapper a{
    position: relative !important;
    margin-top: unset !important;
}
.header_layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(43 43 43 / 76%);
    opacity: 0;
    visibility: hidden;
    z-index: 100000;
    transition: all 0.4s ease;
}

.cm_overflow .header_layer {
    opacity: 1;
    visibility: visible;
}

.cm_overflow {
    overflow: hidden;
}



.header_main .logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    width: 100%;
}
.sidebar_logo{
    width: unset !important;
    margin-left: 0 !important;
}

.header_main .shop {
    display: flex;
    justify-content: flex-end;
}

.header_main .shop-btn {
    border: 1px solid #F2F2F7;
    background: #fff;
    height: 45px;
    width: 100%;
    display: flex;
    /* border-radius: 22.header_main .5px; */
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    border-radius: 22.5px;
    border: 1px solid rgba(242, 242, 247, 1);
    box-shadow: 0px 2px 4px rgba(116, 116, 128, 0.18);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.3s ease;
}

.header_main .shop-btn-icon {
    margin-right: 7px;
    color: #224151;
    font-size: 18px;
    transition: all 0.3s ease;
}

.header_main .shop-btn:hover {
    border: 1px solid #224151;
    color: #224151;
    box-shadow: unset;
}

.header_main .shop-btn:hover .shop-btn-icon {
    color: #fff;
}

.header_main .serch-box {
    position: relative;
    display: flex;
}

.header_main .searching {
    position: relative;
    width: 100%;
    height: 45px;
    border: 1px solid #F2F2F7;
    box-shadow: 0px 3px 3px -2px #bebec1;
    border-radius: 23px;
    color: rgba(159, 162, 169, 1);
    font-size: 15px;
    font-weight: 500;
}
.header_main .searching::placeholder{
    color: #9FA2A9 !important;
}
.searching::placeholder,  .searching:-ms-input-placeholder, .searching::-ms-input-placeholder{
    color: red !important;
    opacity: 1; /* Firefox */
  }
  

.search_input {
    width: 100%;
    height: 100%;
    border: none;
}

.search_input:focus {
    border: none;
}

.header_main .category {
    width: 30%;
    height: 45px;
    border-bottom-right-radius: 23px;
    border-top-right-radius: 23px;
    border: 1px solid #F2F2F7;
    box-shadow: 0px 3px 3px -2px #bebec1;
    margin-left: -5px;
    font-weight: 500;
    font-size: 15px;
    color: #1C1C1E !important;
}

.header_main .search-icon {
    position: absolute;
    top: 14px;
    right: 160px;
    font-size: 18px;
    z-index: 99;
    color: #224151;
}

.header_main .logo-content {
    padding-left: 9px;
}

.header_main .bait {
    font-size: 23.8px;
    font-weight: 400;
    text-transform: uppercase;
    color: #224151;
    font-family: 'Bebas Neue', cursive;
    margin: 0px;
}

.header_main .whole {
    font-size: 11.8px;
    font-weight: 400;
    color: #224151;
    margin: 0px;
}

.header_main .closeNavIcon-responsive {
    display: inline-block;
}

/* @keyframes move-sidebar {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes move-sidebar-inside {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

.header_main .menu-wrapper.show {
    left: 0;
    animation: move-sidebar 1s cubic-bezier(.01, .87, .36, .99);
}

.header_main .menu-wrapper.hide {
    left: 0;
    animation: move-sidebar-inside 1s cubic-bezier(.01, .87, .36, .99);
} */

.header_main .menu {
    /* font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; */
    font-size: 16px;
}

.header_main .menu li {
    display: block;
    position: relative;
}

.header_main .menu li ul {
    background-color: #fff;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    /* height: 0; */
    transition: all;
}

.header_main .menu li .sub-category {
    max-height: 0px;
    transition: all 0.4s ease ;
}

/* .header_main .menu li .sub-category li a{
    color: black ;
} */


.menu_open .header_main .menu .hover_open .sub-category {
    max-height: 1200000px;
}
.header_main .menu .hover_open i{
    transition: all 0.4s ease;
}
.menu_open .header_main .menu .hover_open i {
    transform: rotate(90deg) translateX(5px);
}

.header_main .menu li.active>ul {
    height: initial;
}

.header_main .menu a {
    color: #000;
    text-decoration: none;
    padding: 10px 5px;
    display: block;
    position: relative;
}

.header_main .menu a:hover,
.menu a:hover>i:first-child,
.menu li.active>a,
.menu li.active>a i {
    background-color: #efefef;
    color: #000;
    font-weight: 700;
}

.header_main .menu a .cavet {
    position: absolute;
    right: 10px;
    transform: rotate(0deg);
    transition: transform 0.5s;
    display: inline-block;
    width: 20px;
}

.header_main .menu li.active>a .cavet {
    transform: rotate(90deg);
}

.header_main .menu li+li a {
    border-top: 1px solid #EEEEEE;
}

.header_main .menu a i {
    display: inline-block;
    padding-right: 10px;
    text-align: center;
    width: 20px;
    color: #000;
}

.header_main .sub-category li a {
    color: #043bb1 !important;
    position: relative;
    left: 10px;
}

.header_main .menu,
.header_main .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

::selection {
    background-color: none;
}

/* menu animated icon */
.header_main .menu-icon,
.header_main .menu-icon:before,
.header_main .menu-icon:after {
    display: inline-block;
    background-color: rgb(4 4 4);
    height: 3px;
    width: 20px;
    transition: transform 0.5s cubic-bezier(.01, .87, .36, .99);
    transform-origin: left;
    border-radius: 2px;
}

.header_main .menu-icon:before,
.header_main .menu-icon:after {
    content: "";
    position: absolute;
    left: 0;
}

.header_main .menu-icon {
    position: relative;
    transition: background-color 5;
    animation: fadeIn 1s cubic-bezier(.01, .87, .36, .99) 0s 1 normal forwards;
}

.header_main .menu-icon:before {
    top: 210%;
}

.header_main .menu-icon:after {
    bottom: 237%;
}

.header_main .menu-icon.active {
    animation: fadeOut 0.5s cubic-bezier(.01, .87, .36, .99) 0s 1 normal forwards;
}

@keyframes fadeOut {
    from {
        background-color: rgb(255, 255, 255);
    }

    to {
        background-color: rgba(255, 255, 255, 0);
    }
}

@keyframes fadeIn {
    from {
        background-color: rgba(255, 255, 255, 0);
    }

    to {
        background-color: rgb(255, 255, 255);
    }
}

.header_main .menu-icon:before {
    transform: rotate(-45deg);
}

.header_main .menu-icon:after {
    transform: rotate(45deg);
}

.menu-icon-wrapper a {
    margin-top: 18px;
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
}

.header_main .menu-icon-wrapper {
    position: relative;
    top: 25px;
    z-index: 9999;
    width: 90%;
    position: absolute;
    left: 82%;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    top: 32px;
    left: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_main .menu-icon-wrapper a {
    margin-top: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.header_main .form-control:focus {
    color: #9FA2A9 !important;
    background-color: #fff;
    border-color: #F2F2F7 !important;
    outline: 0;
    box-shadow: none !important;
}

.header_main .form-select:focus {
    border-color: #F2F2F7 !important;
    outline: 0;
    box-shadow: none !important;
}

.header_main .order {
    text-decoration: none;
    color: #3C485E;
    font-size: 13px;
    font-weight: 500;
    margin-right: 15px;
}

.header_main .which {
    text-decoration: none;
    color: #3C485E;
    font-size: 13px;
    font-weight: 500;
    margin-right: 15px;
}

.header_main .account {
    text-decoration: none;
    color: #3C485E;
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
}

.header_main .order-icon {
    color: #e7e7e7;
    margin-right: 5px;
}

.header_main .heart-icon {
    color: #e7e7e7;
    margin-right: 5px;
}

.header_main .user-icon {
    color: #e7e7e7;
    margin-right: 5px;
}

.header_main .bars {
    position: absolute;
    color: #1C1C1E;
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
}

.header_main .bars:hover {
    color: #aaa8a8;
}

.header_main .navbar .megamenu {
    padding: 1rem;
}

.header_main .navbar-dark .navbar-nav .nav-link {
    font-size: 13px;
    text-transform: uppercase;
    color: #1C1C1E;
    font-weight: 500;
}

.header_main .botoom-header {
    border-bottom: 2px solid #EFEFEF;
}

.header_main .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0;
}

.header_main .navbar-dark .navbar-nav .nav-link:focus,
.header_main .navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
}


::-webkit-scrollbar {
    width: 20px;
}


::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.serch-box {
    position: relative;
}

.search_auto {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    max-height: 50vh;
    overflow-y: scroll;
}

.search_result {
    border-bottom: 1px solid #ddd;
}

.search_auto p {
    padding: 5px 12px;
}

.search_auto a:hover {
    text-decoration: none;
    color: #4a89ac;
}

.search_auto div {
    /* width: 450px; */
    border-radius: none;
}

.cart_item_index {
    position: absolute;
    top: -8px;
    left: 8px;
    font-size: 10px;
}

.search_item {
    display: flex;
    justify-content: space-between;
}

.navbar-nav .nav-item {
    margin-right: 20px;
}

.top-header a img {
    width: unset !important;
    margin-right: 5px;
    display: inline-block;
}

.logo-img {
    height: 50px;
    width: 50px;
}

@media (max-width:767px) {
    .header_main .logo{
        margin: 0;
    }
}