.governance_banner img,
.company_banner img {
    width: 100%;
}

.governance_data {
    color: #455560;
}

.governance_item {
    display: flex;
    flex-wrap: wrap;
    column-gap: 88px;
    padding: 100px 0;
}

.governance_item h2 {
    padding-left: 20px;
    border-left: 2px solid #1595D3;
    font-weight: 700;
    font-size: 34px;
    line-height: 44px;
    width: 100%;
    margin: 0px 0px 34px;
}

.governance_item p {
    width: 100%;
    flex: 0 0 calc((100% / 2) - 44px);
    margin: 0;
    line-height: 24px;
}

.company_description {
    display: flex;
    flex-wrap: wrap;
    column-gap: 88px;
    padding: 100px 0;
}

.company_description p {
    margin: 0;
    width: 100%;
    flex: 0 0 calc((100% / 2) - 44px);
    line-height: 24px;
}

@media only screen and (max-width:768px) {
    .scopri_anche_section {
        padding-top: 20px;
    }

    .governance_item,
    .company_description {
        padding: 20px 0;
        row-gap: 40px;
    }

    .governance_item h2 {
        margin-bottom: 0;
        font-size: 24px;
        line-height: 32px;
    }

    .governance_item p,
    .company_description p {
        flex: 0 0 100%;
    }
}