@media(max-width:991px) {
    .col-md-3.pop {
        width: 33%;
    }

    .popular-box-img {
        text-align: center;
    }

    .popular-box {
        /* height: auto; */
        height: 230px;
    }

    .top {
        left: 25px;
    }

    .col-md-3.cli {
        width: 25%;
    }

    .client-img {
        padding-bottom: 20px;
        text-align: center;
    }

    .popular-title {
        padding-bottom: 5px;
    }

    .best-selling {
        padding: 0px 0px;
    }

    .popular-title h2 {
        font-size: 20px;
    }

    .view {
        font-size: 18px;
        right: 30px;
    }

    .live {
        padding: 0px 0px;
    }

    .short-fish {
        padding: 0px 0px;
    }

    .latest {
        padding: 0px 0px;
    }

}



@media(max-width:576px) {
    .swiper {
        height: 200px;
    }

    .col-md-3.pop {
        width: 50%;
    }

    .popular-box {
        padding: 5px;
        /* height: auto; */
    }

    .popular-content {
        padding-top: 0px;
    }

    .popular-content h3 {
        font-size: 13px;
        padding: 0px 0px;
    }

    .popular-icon {
        top: 9%;
        right: 6px;
    }

    .popular-content span {
        font-size: 13px;
    }

    .swiper-slide .title {
        font-size: 30px;
    }

    .subtitle h3 {
        font-size: 17px;
        margin: 0px 5px;
    }

    .swiper-slide {
        padding: 0;
    }

    .swiper-slide .subtitle {
        padding-top: 0px;
    }

    .view {
        position: inherit;
        font-size: 14px;
    }

    .popular-title h2 {
        font-size: 14px;
    }

    .dashboard_container .popular-title h2{
        margin-bottom: 30px;
    }

    .popular-title {
        padding-bottom: 0px;
        justify-content: space-between;
    }

    .popular-title h2::after {
        top: 25px;
    }

    .popular-box {
        margin-bottom: 15px;
    }

    .popular-box-img {
        text-align: center;
    }

    .top {
        left: 25px;
    }

    .popular-title h2::after {
        left: 0;
    }

    .popular {
        padding: 10px 0px;
    }

    .best-selling {
        padding: 10px 0px;
    }

    .home-demo h2 {
        font-size: 9px;
    }

    .all-category {
        padding: 10px 0px 10px 0px;
    }

    .item {
        margin: 0px;
    }

    .col-md-3.cli {
        width: 25%;
    }

    .client-img {
        padding-bottom: 20px;
        text-align: center;
    }






}