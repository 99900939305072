.cart_home_main .main-shedule {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 14px 20px;
  border-bottom: 2px solid #f7f7f7;
}

.cart_home_main {
  font-family: Inter;
}

.cart_home_main .popup .cart_home_main .close {
  color: #4C577D;
  text-decoration: none;
  font-size: 20px;
}

.pop_up_show {
  width: 25vw !important;
  transition: all;
}

.pop_up_hide {
  width: 0 !important;
}

.cart_home_main .box {
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.cart_home_main .box-img {
  width: 30%;
  border: 1px solid #d5d1d1;
  margin-right: 25px;
}

.cart_home_main .icon i {
  color: #FF2D55;
  margin: 30px 0px;
  font-size: 25px;
  cursor: pointer;
}

.cart_home_main .box-img img {
  width: 100%;

  /* height: 200px; */
  height: 120px;
  width: 120px;
  object-fit: contain;

}

.cart_home_main .box-content {
  width: 70%;
}

.cart_home_main .box-content .mpn_number {
  color: rgba(0, 122, 255, 1);
  font-size: 13px;
  font-weight: 700;
}

.cart_home_main .box-content .product_name {
  color: rgba(60, 72, 94, 1);
  font-size: 13px;
  font-weight: 700;
}

.cart_home_main .box-content .product_price {
  color: rgba(0, 122, 255, 1);
  font-size: 15px;
  font-weight: 700;
}

.cart_home_main .box-content .product_upc {
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 500;
}

.cart_home_main .box-content .product_availibility {
  color: rgba(48, 209, 88, 1);
  font-size: 13px;
  font-weight: 500;
}

.cart_home_main .box-content h2 {
  font-size: 15px;
  font-weight: 700;
  color: #3C485E;
  margin: 0px;
}

.cart_home_main .box-content p {
  font-size: 15px;
  font-weight: 700;
  color: #999999;
  margin: 4px 0px;
}

.cart_home_main .box-content span {
  font-size: 13px;
  font-weight: 600;
  color: #999999;
}

.cart_home_main .box-content small {
  color: #44D7B6;
  font-size: 13px;
  font-weight: 600;
}

.cart_home_main .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 2px solid #f7f7f7;
  border-top: 2px solid #f7f7f7;
  margin-top: 10px;
}

.cart_home_main .footer h2 {
  color: rgba(60, 72, 94, 1);
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.cart_home_main .footer span {
  color: rgba(60, 72, 94, 1);
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.cart_home_main .view_home {
  padding: 18px 15px 35px 15px;
  width: 100%;
}

.cart_home_main .view-btn {
  background: #224151;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  height: 46px;
  /* border-radius: 23px; */
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.cart_home_main .view-btn:hover {
  border-color: #224151;
  background: transparent;
  color: #224151;
}

.cart_home_main .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.cart_home_main .7); */
  background-color: black;
  opacity: 70%;
  transition: opacity 500ms;
  z-index: 10000;
  /* visibility: hidden; */
  /* opacity: 0; */
}

.cart_home_main .overlay:target {
  visibility: visible;
  opacity: 1;
}

.cart_home_main .popup {
  /* margin: 70px auto; */
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  width: 25vw;
  /* position: relative; */
  z-index: 100;
  transition: all 1s ease-in-out;
  box-shadow: 0px 0px 15px -5px #808080;
  z-index: 100000;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 40px;
}

.cart_home_main .schedule {
  font-size: 20px;
  font-weight: 700;
  color: #313131;
  margin: 0px;
  text-transform: uppercase;
}

.cart_home_main .popup .close:hover {
  color: #06D85F;
}

@media screen and (max-width: 700px) {
  .cart_home_main .box {
    width: 100%;
  }

  .cart_home_main .popup {
    width: 90%;
  }
  .empty_cart img{
    width: 130px;
    height: 130px;
    max-width: unset;
  }
}

.empty_cart {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty_cart_weight {
  font-weight: 700;
  font-size: 18px;
}

.empty_cart button a:hover {
  text-decoration: none !important;
}

.close_btn {
  cursor: pointer;
}