.azienda_banner_section .page_desc {
    padding: 50px 20px;
}

.azienda_banner_section .page_desc p {
    margin: 0;
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    color: #455560;
    width: 100%;
    max-width: 1040px;
}

.azienda_banner_section .banner_img img {
    width: 100%;
    height: 500px;
}
.future_section .future_content, .timeline_section .timeline_content {
    padding: 40px 80px 20px 80px;
}

.future_section .future_items,
.timeline_section .timeline_items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 222px;
}

.future_section .future_items h1,
.timeline_section .timeline_items h1 {
    width: 100%;
    margin: 0;
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 35px;
}

.future_section .future_items p,
.timeline_section .timeline_items p {
    width: 100%;
    flex: 0 0 calc((100% / 2) - 111px);
    margin: 0;
    line-height: 24px;
}

.future_image_section, .filma_image_section {
    display: flex;
    flex-wrap: wrap;
    column-gap: 42px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0px 80px;
}

.future_image_section img, .filma_image_section img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-width: 100%;
    object-fit: cover;
}

.future_image_section .snippet_sqr,
.filma_image_section .snippet_sqr {
    position: relative;
    padding-top: 45.197%;
    overflow: hidden;
    flex: 0 0 65%;
}

.future_image_section .snippet_rec,
.filma_image_section .snippet_rec {
    position: relative;
    overflow: hidden;
    flex: 0 0 calc(35% - 42px);
    padding-top: 35.533%;
}

.filma_content {
    padding: 80px 80px;
}

.filma_count_section {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10rem;
    row-gap: 70px;
    padding: 50px 0 100px;
}

.filma_count_section .count_items {
    width: calc((100% / 3) - 107px);
    border-left: 2px solid #1595D3;
    padding-left: 20px;
    box-sizing: border-box;
}

.filma_count_section .count_items h1 {
    font-size: 120px;
    line-height: 125px;
    margin: 0;
    font-weight: 300;
}

.filma_count_section .count_items h1 span {
    font-size: 30px;
    line-height: 38px;
    font-weight: 300;
    padding-left: 20px;
}

.filma_count_section .count_items h1 span sup {
    font-size: 19px;
    line-height: 24px;
}

.filma_count_section .count_items p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.filma_content .filma_desc p {
    margin: 0;
    width: 100%;
    max-width: 553px;
    line-height: 24px;
}

.timeline_section .timeline_wrapper .title h1 {
    margin: 0;
    padding: 80px 0;
}

.timeline_section .timeline_count_section {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid #1595D3;
    padding: 0px 80px;
}

.timeline_count_section .count_items {
    flex: 1;
    border-left: 2px solid #1595D3;
    padding-left: 20px;
    padding-bottom: 80px;
    box-sizing: border-box;
}

.timeline_count_section .count_items h1 {
    margin: 0;
    font-size: 120px;
    line-height: 153px;
    font-weight: 400;
}

.timeline_count_section .count_items p {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
}


.timeline_section .timeline_content img {
    width: 100%;
}

.timeline_section .timeline_items {
    padding-bottom: 70px;
}

.timeline_section .global_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0 0;
}

.timeline_section .global_section .title h1 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    margin: 0;
}

.timeline_section .global_section .desc p {
    max-width: 553px;
    margin: 0;
    line-height: 24px;
}

.colorama_section .banner {
    position: relative;
}

.colorama_section .banner img {
    width: 100%;
}

.colorama_section .banner .banner_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.colorama_section .banner_desc p {
    width: 100%;
    max-width: 533px;
    padding: 100px 0px;
    line-height: 24px;
}

.colorama_snippet_section {
    display: flex;
    padding-bottom: 150px;
    padding-left: 80px;
    padding-right: 80px;
}

.colorama_snippet_section .snippet_item {
    flex: 1;
}

.colorama_snippet_section .snippet_item img {
    width: 100%;
}

.award_section {
    padding-bottom: 150px;
}

.award_section .award_wrapper {
    display: flex;
    column-gap: 68px;
}

.award_section .img_section img {
    width: 100%;
}

.award_section .award_content p {
    margin: 0;
    line-height: 30px;
    margin-bottom: 40px;
}

.award_section .award_content ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.award_section .award_content ul li {
    line-height: 30px;
}

@media only screen and (max-width: 1024px) {
    .azienda_banner_section .page_desc {
        padding: 40px 20px 60px;
    }

    .filma_content {
        padding: 20px 20px;
    }

    .filma_content .title h1 {
        margin: 0;
        font-size: 24px;
        line-height: 32px;
    }

    .filma_count_section {
        column-gap: 26px;
        row-gap: 40px;
        padding: 20px 0 40px;
    }

    .filma_count_section .count_items {
        width: calc((100% / 3) - 37px);
    }

    .filma_count_section .count_items h1 {
        font-size: 60px;
        line-height: 76px;
    }

    .filma_count_section .count_items h1 span {
        font-size: 24px;
        line-height: 30px;
        padding-left: 0;
    }

    .future_section .future_content,
    .timeline_section .timeline_content {
        padding: 20px 20px 20px;
    }

    .future_section .future_items,
    .timeline_section .timeline_items {
        column-gap: 50px;
    }

    .future_section .future_items p,
    .timeline_section .timeline_items p {
        flex: 0 0 calc((100% / 2) - 25px);
    }

    .timeline_section .timeline_wrapper .title h1 {
        padding: 50px 0 35px;
        font-size: 24px;
        line-height: 32px;
    }

    .timeline_count_section .count_items h1 {
        font-size: 60px;
        line-height: 76px;
    }

    .colorama_section .banner_desc p {
        max-width: 100%;
        padding: 40px 0;
        margin: 0;
    }
}

@media only screen and (max-width: 767px) {
    .filma_content .filma_desc p {
        max-width: 100%;
    }

    .award_section .award_wrapper {
        row-gap: 40px;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .timeline_section .timeline_items,
    .colorama_snippet_section,
    .award_section {
        padding-bottom: 60px;
    }

    .azienda_banner_section .page_desc p {
        font-size: 24px;
        line-height: 32px;
    }

    .timeline_section .timeline_count_section {
        border-bottom: 0;
    }

    .timeline_count_section .count_items {
        flex: 0 0 100%;
        padding: 20px;
        border-bottom: 2px solid #1595D3;
    }

    .future_section .future_items p,
    .timeline_section .timeline_items p {
        flex: 0 0 100%;
    }

    .timeline_section .timeline_items,
    .colorama_snippet_section,
    .award_section {
        flex-wrap: wrap;
    }

    .future_section .future_items h1,
    .timeline_section .timeline_items h1 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0px;
    }

    .colorama_snippet_section .snippet_item {
        flex: 0 0 100%;
    }

    .filma_count_section .count_items {
        width: calc((100% / 2) - 13px);
    }

    .timeline_section .global_section {
        flex-wrap: wrap;
        row-gap: 40px;
        padding-top: 0px;
    }

    .future_image_section,
    .filma_image_section {
        padding: 0 20px;
        row-gap: 20px;
    }

    .future_image_section .snippet_sqr,
    .filma_image_section .snippet_sqr,
    .future_image_section .snippet_rec,
    .filma_image_section .snippet_rec {
        flex: 0 0 100%;
        padding-top: 0;
    }

    .future_image_section img,
    .filma_image_section img {
        position: unset;
        transform: unset;
    }

    .timeline_section .global_section .title h1 {
        font-size: 24px;
        line-height: 32px;
    }

    .colorama_snippet_section {
        row-gap: 20px;
        padding-bottom: 40px;
    }

    .future_section .future_items,
    .timeline_section .timeline_items {
        row-gap: 40px;
    }

    .colorama_section .banner_desc p {
        padding: 0px 0 40px;
    }
}