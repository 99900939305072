/* Main Content */
.hero_banner {
    z-index: 5;
    position: relative;
    padding-bottom: 50px;
}

.hero_banner img {
    width: 100%;
}

/* Search Section Start */
.search_section {
    margin-top: -94px;
    z-index: 9;
    position: relative;
}

.search_section .search_wrapper {
    max-width: 960px;
    background: #ffffff;
    border-radius: 12px;
    padding: 34px 30px;
    margin-bottom: 100px;
}

.search_section .search_wrapper h3 {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #455560;
    margin-bottom: 20px;
}

.search_section .search_dropdown {
    display: flex;
    column-gap: 20px;
    align-items: flex-end;
}

.search_section .search_item {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search_section .search_item label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: #455560;
}

.search_section .search_item select {
    background-color: #F5F5F5;
    border-radius: 12px;
    min-height: 40px;
    outline: 0;
    padding: 0 15px;
}

.search_section .search_item .action {
    width: 100%;
    max-width: 150px;
    background: #455560;
    border-radius: 12px;
    box-sizing: border-box;
    text-align: center;
}

.search_section .search_item .action a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: 11px;
    display: block;
}

.search_item.composition {
    flex: 0 0 40%;
}

.search_item.nm,
.search_item.ne {
    flex: 0 0 13%;
}

.search_item.finezza {
    flex: 0 0 16%;
}

/* Search Section End */

/* Snippet Section Start */
.image_text_snippet .snippet_items {
    display: flex;
    column-gap: 50px;
    padding-bottom: 120px;
}

.image_text_snippet .snippet_items .snippet_rec {
    display: flex;
    align-items: flex-end;
}

.snippet_items .snippet_content h2 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    color: #455560;
    margin: 0;
    margin-bottom: 10px;
}

.snippet_items .snippet_content span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #455560;
    display: block;
    margin-bottom: 23px;
}

.snippet_items .snippet_content p {
    font-size: 18px;
    line-height: 34px;
    font-weight: 400;
    color: #455560;
    margin: 0;
    margin-bottom: 40px;
}

.snippet_items .snippet_content .link {
    width: 100%;
    max-width: 146px;
    background-color: #F5F5F5;
    border-radius: 12px;
    text-align: center;
    padding: 10px 0;
}

.snippet_items .snippet_content .link a {
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
}

/* Snippet Section End */

/* Highlight Section Start */
.highlight_section .highlight_wrapper {
    display: flex;
    column-gap: 46px;
    padding-bottom: 90px;
}

.highlight_section .highlight_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
}

.highlight_section .highlight_data {
    position: relative;
}

.highlight_section .highlight_data img {
    width: 100%;
}

.highlight_section .highlight_content {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: #fff;
}

.highlight_section .highlight_content span.tag {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    display: block;
}

.highlight_section .highlight_content p.desc {
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 20px;
}

.highlight_data.sosten p.desc {
    max-width: 300px;
}

.highlight_data.azienda p.desc {
    max-width: 392px;
}

.highlight_section .highlight_content .link {
    width: 100%;
    max-width: 105px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 10px 0;
    text-align: center;
}

.highlight_section .highlight_content .link a {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
    text-decoration: none;
}

/* Highlight Section End */

/* News Section Start */
.news_section .title h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 40px;
    color: #455560;
}

.news_section .news_content {
    display: flex;
    column-gap: 20px;
    padding-bottom: 150px;
}

.news_section .news_content .news_item {
    flex: 1;
    position: relative;
}

.news_section .news_item img {
    width: 100%;
}

.news_section .news_data {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: #ffffff;
}

.news_section .news_data p {
    margin: 0;
}

.news_section .news_data p span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.news_section .news_data p span.date {
    padding-right: 15px;
    font-weight: 400;
}

.news_section .news_data p.name {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

/* News Section End */

/* Seller Section Start */
.seller_section {
    padding-bottom: 80px;
}

.seller_section .title ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    column-gap: 20px;
    margin-bottom: 34px;
}

.seller_section .title ul li a {
    text-decoration: none;
    font-size: 24px;
    line-height: 32px;
    color: rgba(69, 85, 96, 0.5);
    font-weight: 700;
}

.seller_section .title ul li.active a {
    color: #455560;
    border-bottom: 2px solid #1595D3;
}

.seller_section .seller_content {
    display: flex;
    /*    flex-wrap: wrap;*/
    column-gap: 5px;
}

.seller_section .seller_content .seller_item {
    flex: 1;
}

.seller_section .seller_data span.tag {
    color: #455560;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    padding: 10px 0;
    display: block;
}

.seller_section .seller_data p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
    margin: 0;
}

.seller_section .seller_data .count {
    color: #999999;
}

.seller_section .seller_data .item_color {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 10px;
}

.seller_section .seller_data .color {
    display: flex;
}

.seller_section .seller_data .color span {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-left: -5px;
}

.seller_section .seller_data .color span.red {
    background: #DF1A57;
}


.seller_section .seller_data .color span.brown {
    background: #AD7850;
}

.seller_section .seller_data .color span.blue {
    background: #1595D3;
}

/* Seller Section End */

/* Customer Section Start */
.customer_content {
    display: flex;
    padding-bottom: 120px;
}

.customer_content>div {
    flex: 1;
}

.customer_content .tag_line h1 {
    font-size: 44px;
    line-height: 58px;
    font-weight: 700;
    color: #455560;
    width: 100%;
    max-width: 622px;
    margin: 0;
}

.customer_content form .form_control input {
    width: 100%;
    margin-bottom: 20px;
    min-height: 50px;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
}

.customer_content form textarea {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    padding: 20px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
}

.customer_content form .form_control input:focus,
.customer_content form textarea:focus {
    outline: 0;
}

.customer_content form .form_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer_content form .form_action .checkbox {
    display: flex;
    column-gap: 10px;
}

.customer_content form .form_action .checkbox a {
    color: #455560;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.customer_content form .form_action .button_action {
    background-color: #F5F5F5;
    border: 0;
    padding: 10px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #455560;
    width: 100%;
    max-width: 138px;
}

/* Customer Section End */
@media only screen and (max-width: 1600px) {
    .seller_section .seller_content .seller_item img {
        width: 100%;
        height: 350px;
    }
}


@media only screen and (max-width: 1024px) {
    .highlight_section .highlight_wrapper {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .highlight_section .highlight_wrap {
        row-gap: 20px;
        flex: 0 0 100%;
    }

    .seller_section .seller_content {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
    }

    .seller_section .seller_content .seller_item {
        flex: 0 0 calc((100% / 2) - 10px);
    }

    .seller_section .seller_content .seller_item img {
        height: 530px;
    }

    .image_text_snippet .snippet_items img {
        width:  60%;
        margin: auto;
    }

    .image_text_snippet .snippet_items>div {
        width: 100%;
    }

    .image_text_snippet .snippet_items {
        flex-wrap: wrap;
        row-gap: 20px;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .seller_section .seller_content .seller_item {
        flex: 0 0 100%;
    }

    .seller_section .seller_content .seller_item img {
        height: 365px;
    }

    .search_section .search_dropdown {
        flex-wrap: wrap;
    }

    .search_section {
        padding: 0 20px;
    }

    .search_section .search_item {
        flex: 0 0 100%;
        padding-bottom: 20px;
    }

    .search_section .search_wrapper {
        margin-bottom: 50px;
    }

    .customer_content {
        flex-wrap: wrap;
    }

    .customer_content>div {
        flex: 0 0 100%;
    }

    .customer_content .tag_line h1 {
        text-align: center;
        padding-bottom: 20px;
        font-size: 28px;
        line-height: 38px;
    }

    .news_section .news_content {
        flex-wrap: wrap;
        row-gap: 20px;
        padding-bottom: 50px;
    }

    .news_section .news_content .news_item {
        flex: 0 0 100%;
    }

    .highlight_section .highlight_data img {

        height: 450px;
        object-fit: cover;
    }
}




@media(max-width:576px){
    .image_text_snippet {
        padding-left: 30px;
        padding-right: 30px;
    }
    .hero_banner {
        padding-bottom: 25px;
    }
    .snippet_items .snippet_content h2 {
        font-size: 25px;
        margin-bottom: 0px;
    }
    .snippet_items .snippet_content span {
        margin-bottom: 10px;
    }
    .snippet_items .snippet_content p {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .image_text_snippet .snippet_items {
        padding-bottom: 20px;
    }
}