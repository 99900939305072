:root {
    --Independence: #455560;
    --light-blue: #1595D3;
    --White-Smoke: #F5F5F5;
    --Light-Grey: #D9D9D9;
    --white: #FFFFFF;
}

/* @font-face {
    font-family: 'DIN2014';
    src: url('../fonts/DIN/DIN2014-Bold.woff');
    src: url('../fonts/DIN/DIN2014-Bold.woff2');
    src: url('../fonts/DIN/DIN2014-Light.woff');
    src: url('../fonts/DIN/DIN2014-Light.woff2');
    src: url('../fonts/DIN/DIN2014-Regular.woff');
    src: url('../fonts/DIN/DIN2014-Regular.woff2');
} */

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'DIN2014'; */
    color: #455560;
}

* {
    box-sizing: border-box;
}

.snippet_items img{
    max-width: 100%;
}
.image_text_snippet {
    padding-left: 70px;
    padding-right: 70px;
}

.cms_container {
    width: 100%;
    max-width: 1368px;
    padding: 0 80px;
    margin: 0 auto;
    box-sizing: border-box;
}

.mobile_img {
    display: none;
}

.scopri_anche_section {
    border-top: 2px solid var(--Light-Grey);
    padding-top: 42px;
    margin-bottom: 50px;
}

.scopri_anche_title h2,
.scopri_anche_title h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin: 0px 0px 22px;
}

.scopri_anche_item {
    display: flex;
    column-gap: 24px;
    overflow-x: auto;
    padding-bottom: 10px;
}

.scopri_anche_box {
    background-color: var(--White-Smoke);
    border-radius: 12px;
    padding: 12px 24px;
    cursor: pointer;
    flex: 0 0 auto;
}

.scopri_anche_box a {
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: var(--Independence);
}


/* @font-face {
    font-family: 'DIN 2014';
    src: url('../fonts/DIN/DIN2014-Bold.woff2') format('woff2'),
        url('../fonts/DIN/DIN2014-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('../fonts/DIN/DIN2014-Regular.woff2') format('woff2'),
        url('../fonts/DIN/DIN2014-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('../fonts/DIN/DIN2014-Light.woff2') format('woff2'),
        url('../fonts/DIN/DIN2014-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
} */

/* Header */
.header_wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
}

.nav_section nav ul {
    display: flex;
    list-style: none;
    column-gap: 30px;
    padding-left: 0;
}

.nav_section {
    display: flex;
    column-gap: 50px;
    align-items: center;
}

.nav_section span.toggle_icon {
    display: none;
}

.toggle_section a.toggle:after {
    content: "";
    background: url(../images/toggle.png) no-repeat;
    width: 25px;
    height: 40px;
    display: block;
    margin: 0 auto;
    background-position: center;
}

.nav_section .logo {
    padding-left: 20px;
}

.nav_section ul li a {
    color: #455560;
    font-size: 16px;
    line-height: 21px;
    text-decoration: none;
}

.header_links {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.header_links .header_items {
    border-left: 1px solid #D9D9D9;
    min-height: 60px;
    flex: 0 0 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_items.toggle_section {
    display: none;
}

.header_links a {
    text-decoration: none;
    color: #455560;
    font-size: 16px;
    line-height: 21px;
    width: 100%;
    display: block;
    text-align: center;
}

.header_links .country_dropdown {
    position: relative;
}

.country_dropdown .country_options {
    display: none;
    position: absolute;
    top: 61px;
    background: #fff;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.country_dropdown ul {
    list-style: none;
    padding-left: 0;
    text-align: center;
    margin: 0;
    padding: 0 10px;
}

.country_dropdown ul li {
    padding: 5px 0px;
    border-bottom: 1px solid #D9D9D9;
}

.country_dropdown ul li:last-child {
    border-bottom: 0;
}

.header_items a:after {
  
    font-size: 20px;
    vertical-align: middle;
    padding-left: 5px;
    line-height: 1;
}

.country_dropdown .country_current:after {
    content: "\f107";
}

.header_items .search:after {
    content: "\f002";
}

.header_items .wishlist:after {
    content: "\f005";
}

.header_items .login:after {
    content: "\f007";
}

/* Header End */



/* Footer */
.footer_section {
    background-color: #455560;
}

.footer_wrapper {
    padding: 30px 40px;
}

.footer_data {
    display: flex;
    justify-content: space-between;
}

.footer_data>div {
    flex: 1;
}

.footer_data .footer_links {
    display: flex;
}

.footer_data .footer_links>div {
    flex: 0 0 25%;
}

.footer_data .footer_links>div.newsletter {
    flex: 1;
}

.footer_links p {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
}

.footer_links p.tag_line {
    width: 100%;
    max-width: 272px;
    margin-top: 15px;
    margin-bottom: 35px;
}

.footer_links .location {
    margin-bottom: 22px;
}

.footer_links .contect {
    margin-bottom: 40px;
}

.footer_links .contect p a {
    color: #ffffff;
    margin: 0;
    text-decoration: none;
}

.footer_links .social ul {
    display: flex;
    list-style: none;
    padding-left: 0;
    column-gap: 22px;
    margin-bottom: 46px;
}

.footer_links .social ul a:after {
  
    color: #fff;
    font-size: 24px;
}

.footer_links .social ul a.facebook:after {
    content: "\f09a";
}

.footer_links .social ul a.instagram:after {
    content: "\f16d";
}

.footer_links .social ul a.youtube:after {
    content: "\f167";
}

.footer_links .pages_link ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

.footer_links .pages_link ul li {
    padding-bottom: 20px;
}

.footer_links .pages_link ul a {
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
}

.newsletter h3 {
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
}

.newsletter p.tag_line {
    font-size: 14px;
    margin-bottom: 35px;
    margin-top: 15px;
    max-width: 394px;
}

.newsletter p {
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
    max-width: 394px;
    margin-top: 0;
    margin-bottom: 25px;
}

.newsletter form {
    position: relative;
}

.newsletter form input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #ffffff;
    min-height: 30px;
    outline: 0;
    color: #fff;
    margin-bottom: 25px;
}

.newsletter form button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: 0;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
    padding: 0;
}

.footer_dropdown {
    position: relative;
    width: 100%;
    max-width: 86px;
    margin-left: auto;
}

.footer_dropdown .country_options {
    display: none;
    position: absolute;
    top: 43px;
    right: 0;
    width: 100%;
    background: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
}

.footer_dropdown .country_options ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.footer_dropdown .country_options ul li {
    padding: 5px 0px;
    border-bottom: 1px solid #D9D9D9;
    text-align: center;
}

.footer_dropdown .country_options ul a {
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    display: block;
}

.footer_dropdown .country_options ul li:last-child {
    border-bottom: 0;
}

.footer_dropdown a.country_current {
    border: 1px solid #fff;
    display: block;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    color: #fff;
    text-decoration: none;
}

.footer_dropdown a.country_current:after {
    content: "\f107";
  
    font-size: 20px;
    vertical-align: middle;
    padding-left: 5px;
    line-height: 1;
}

.footer_section .form_link {
    display: none;
}

.copyright {
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.copyright p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
}

.copyright p a {
    text-decoration: none;
    color: #ffffff;
}

.copyright .page_link {
    display: flex;
    flex-wrap: wrap;
    column-gap: 45px;
}

/* Footer End */

@media only screen and (max-width: 1024px) {
    .header_links {
        column-gap: 20px;
        z-index: 11;
    }

    .header_wrapper {
        padding: 20px;
    }

    .header_links .header_items {
        flex: 0 0 auto;
        min-height: 40px;
        border-left: 0;
    }

    .header_links .header_items.country_dropdown,
    .header_links .header_items.search_field {
        display: none;
    }

    .header_links .header_items.toggle_section {
        display: block;
    }

    .header_links a {
        font-size: 0;
    }

    .header_items a:after {
        padding-left: 0;
        font-size: 24px;
    }

    .nav_section {
        column-gap: 0;
    }

    .nav_section span.toggle_icon {
        display: block;
    }

    .nav_section .logo {
        padding-left: 0;
    }

    .nav_section a.logo_link {
        line-height: 0;
    }

    .footer_wrapper {
        padding: 40px 20px;
    }

    .nav_section nav {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        z-index: 10;
        background: #fff;
        min-height: 100vh;
        padding: 0 20px;
        transition: opacity .3s ease-in-out;
    }

    nav.active {
        opacity: 1;
        z-index: 12;
        visibility: visible;
    }

    nav .mobile_logo {
        padding: 20px 0;
    }

    nav.active .mobile_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    nav.active .mobile_logo a {
        line-height: 0;
    }

    nav.active .mobile_logo .close_icon:after {
        content: "";
        background: url(../images/close.png) no-repeat;
        width: 25px;
        height: 25px;
        display: block;
        cursor: pointer;
    }

    .mobile_navber .search {
        position: relative;
        margin-bottom: 20px;
    }

    .mobile_navber .icon_search:after {
        content: "\f002";
      
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .mobile_navber .search input {
        width: 100%;
        min-height: 50px;
        border-radius: 12px;
        border: 0;
        background: #F5F5F5;
        padding: 16px 20px;
        outline: 0;
        color: #455560;
    }

    .nav_section nav.active ul {
        flex-direction: column;
    }

    .nav_section nav.active>ul {
        margin-bottom: 60px;
    }

    .nav_section nav.active ul li.level0 a {
        padding: 10px 5px;
        display: inline-block;
        text-transform: capitalize;
    }

    .nav_section ul li.level0.parent {
        position: relative;
    }

    .nav_section ul li.level0.parent:after {
        content: "\f107";
      
        margin-left: 5px;
        position: absolute;
        left: 100px;
        top: 6px;
        font-size: 25px;
        color: #1595D3;
    }

    .nav_section nav.active .submenu {
        max-height: 0;
        overflow: hidden;
        padding-left: 10px;
    }

    .nav_section nav.active .submenu.active {
        max-height: 400px;
        animation: slide .4s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes slide {
        0% {
            max-height: 0;
        }

        100% {
            max-height: 400px;
        }
    }

    .mobile_navber .user_section {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
    }

    .mobile_navber .user_section .header_items {
        flex: 1;
        background-color: #F5F5F5;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
    }

    .mobile_navber .user_section .header_items a {
        text-decoration: none;
        color: #455560;
    }

    .mobile_navber .header_items a:after {
        padding-left: 10px;
        font-size: 20px;
    }

    .menu_items.country_dropdown {
        width: 100%;
        max-width: 110px;
        border-radius: 12px;
        min-height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D9D9D9;
    }

    .menu_items.country_dropdown a {
        text-decoration: none;
        color: #455560;
    }

    .menu_items.country_dropdown a:after {
        content: "\f078";
      
        padding-left: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .footer_data {
        flex-wrap: wrap;
    }

    .footer_data>div {
        flex-wrap: wrap;
    }

    .footer_data .footer_links>div {
        flex: 0 0 100%;
    }

    .footer_data .footer_links>div.pages_link {
        order: 1;
    }

    .footer_data .footer_links>div.newsletter {
        order: 2;
        margin-bottom: 60px;
    }

    .footer_data .footer_links>div.address {
        order: 3;
    }

    .footer_links p.tag_line {
        max-width: 315px;
        margin-bottom: 20px;
    }

    .newsletter form input {
        border: 1px solid #F5F5F5;
        min-height: 50px;
        border-radius: 12px;
        margin-bottom: 20px;
    }

    .newsletter form button {
        font-size: 14px;
        line-height: 18px;
        min-height: 50px;
        right: 15px;
    }

    .footer_links .contect {
        margin-bottom: 20px;
    }

    .footer_links .social ul {
        margin-bottom: 40px;
    }

    .footer_section .form_link {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .footer_section .form_link p {
        margin-bottom: 0;
    }

    .footer_section .form_link p a {
        color: #FFFFFF;
        font-size: 14px;
        line-height: 18px;
    }

    .footer_section .form_link .checkbox {
        display: flex;
        column-gap: 5px;
    }

    .footer_links .pages_link ul {
        margin-bottom: 20px;
    }

    .footer_section .newsletter p.req_field {
        max-width: unset;
        flex: 1;
        text-align: right;
    }

    .newsletter p.description {
        display: none;
    }

    .footer_dropdown {
        margin-left: 0;
    }

    .copyright {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .copyright .page_link {
        row-gap: 20px;
    }

    .copyright .page_link a {
        width: 100%;
    }

    .desktop_img {
        display: none;
    }

    .mobile_img {
        display: block;
    }

    .scopri_anche_section {
        margin-bottom: 160px;
    }

    .scopri_anche_item {
        column-gap: 14px;
    }

    .desktop_img {
        display: none;
    }

    .mobile_img {
        display: block;
    }

    .scopri_anche_section {
        margin-bottom: 40px;
    }

    .scopri_anche_item {
        column-gap: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .footer_data .footer_links>div {
        flex-wrap: wrap;
        flex: 0 0 100%;
    }

    .footer_dropdown {
        margin-left: 0;
    }

}

/* Menu Start*/
@media only screen and (min-width: 1025px) {

    .header_wrapper {
        position: relative;
    }

    .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        background: #FFF;
        padding: 40px 40px 40px 24rem;
        z-index: 10;
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid #D9D9D9;
        display: none;
        border-bottom: 1px solid #D9D9D9;
    }

    .nav_section nav>ul {
        margin: 0;
    }

    .nav_section nav>ul li:hover .submenu {
        display: flex;
    }

    .nav_section nav>ul li a {
        padding: 22px 0;
        position: relative;
    }

    .nav_section nav>ul li:not(.submenu_item)>a:hover:after,
    .nav_section nav>ul li:not(.submenu_item)>a.active:after {
        content: "";
        background: #1595D3;
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% - 3px);
        height: 2px;
        z-index: 11;
    }

    .nav_section ul.submenu_list {
        flex-direction: column;
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    .submenu ul.submenu_list li {
        margin-bottom: 20px;
    }

    .submenu ul.submenu_list li a {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 0;
    }

    .mobile_navber {
        display: none;
    }

    nav .mobile_logo,
    nav .menu_items.country_dropdown {
        display: none;
    }
}

/* Menu End*/