h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.banner_img {
    margin-bottom: 120px;
}

.banner_img img {
    width: 100%;
}

.certifications {
    margin-bottom: 120px;
}

.certifications_heading {
    margin-bottom: 37px;
}

.certifications_heading h2 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    padding-left: 20px;
    border-left: 2px solid var(--light-blue);
}

.certifications_items {
    border-top: 1px solid var(--Light-Grey);
    padding-top: 53px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.certifications_items .certificate {
    display: flex;
    column-gap: 30px;
    flex: 0 0 calc((100% - 40px) / 2);
    align-items: flex-start;
}

.certificate_img_section {
    flex: 0 0 37%;
    background-color: var(--White-Smoke);
    padding: 7px 8px;
    max-width: 232px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.certificate_img {
    padding-top: 100%;
    position: relative;
}

.certificate_img img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.certificate_text {
    flex: 1;
}

.certificate_text h3 {
    margin: 0px 0px 18px;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
}

.certificate_text p {
    font-size: 16px;
    line-height: 24px;
    max-width: 361px;
}

@media only screen and (max-width:1024px) {

    .banner_img,
    .certifications {
        margin-bottom: 30px;
    }

    .certifications_items {
        gap: 20px
    }

    .certifications_items .certificate {
        flex-direction: column;
        row-gap: 20px;
        flex: 0 0 calc((100% - 20px) / 2);
        border: 1px solid var(--Light-Grey);
        padding: 20px;
        text-align: center;
    }

    .certificate_img_section,
    .certificate_text {
        margin: 0 auto;
    }
}

@media only screen and (max-width:768px) {
    .certifications_heading {
        margin-bottom: 15px;
    }

    .certifications_items {
        padding-top: 30px;
        margin-bottom: 100px;
    }

    .certifications_items .certificate {
        flex: 0 0 100%;
        flex-direction: row;
        align-items: center;
        text-align: left;
        border-bottom: 1px solid var(--Light-Grey);
    }

    .certifications_heading h2 {
        font-size: 24px;
        line-height: 32px;
        padding-left: 20px;
    }

    .certificate_text h3 {
        margin: 0px 0px 18px;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
    }

    .certificate_text p {
        font-size: 16px;
        line-height: 24px;
    }

    .certificate_img_section,
    .certificate_text p {
        margin: 0px;
        max-width: 100%;
    }
}

@media only screen and (max-width:480px) {
    .certificate {
        padding: 10px;
    }

    .certificate_img {
        padding-top: 0;
    }

    .certificate_img_section {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 216px;
    }

    .certificate_img img {
        position: unset;
        transform: unset;
    }

    .certifications_items .certificate {
        flex-direction: column;
        flex: 0 0 100%;
        text-align: center;
        border: 0;
        padding: 40px 0;
        border-bottom: 1px solid var(--Light-Grey);
    }

    .certifications_items {
        gap: 0px;
        padding-top: 0px;
    }

    .certificate_text {
        text-align: left;
    }
}