@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

body {
  background: #fff;
  font-size: 16px;
  color: #000;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  min-height: 100vh !important;
  position: relative;
  /* height: 100vh; */
  height: 100%;
  overflow-x: hidden;
  /* font-family: 'Heebo', sans-serif !important; */
  /* font-family: Inter !important; */
  font-family: 'Inter', sans-serif;





}

body {
  margin: 0px;
  padding: 0px;
}

.container {
  max-width: 1250px !important;
}
.product_container.container {
  max-width: 96% !important;
}

.dashboard_container.container {
  max-width: 1322px !important;
}

img {
  max-width: 100%;
}

.swiper {
  width: 100%;
  height: 400px;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  box-sizing: border-box;
  /* padding: 150px 60px 40px 130px; */
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff
    /* background-image: url('../images/home/banner.png'); */
}
.parallax-bg img{
  object-position: center;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  background-color: transparent;
  padding: 10px 21px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-weight: 700;
}

.swiper-button-prev {
  background-color: transparent;
  padding: 10px 21px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-weight: 700;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}

.swiper-slide .title {
  font-size: 48px;
  font-weight: 900;
  color: #fff;
  font-family: 'Heebo', sans-serif;
}

.swiper-slide .subtitle {
  font-size: 16px;
  display: flex;
  font-weight: 600;
  align-items: baseline;
  padding-top: 15px;
}

.subtitle h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0px 8px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.all-category {
  position: relative;
  background: #F3F3F3;
  padding: 50px 0px 31px 0px;
}

.home-demo h2 {
  text-align: center;
  margin: 0;
  /* font-style: italic; */
  font-weight: 700;
  font-size: 15px;
  padding-top: 25px;
  color: #3A3A3C;
  text-transform: uppercase;
  font-family: 'Heebo', sans-serif;
}

.item a {
  text-decoration: none;
  font-family: 'Heebo', sans-serif;
}

.view {
  right: 0;
  position: absolute;
  text-decoration: none;
  color: #224151;
  font-size: 22px;
  font-family: 'Heebo', sans-serif;
  font-weight: 500;
  opacity: 1;
  color: rgba(0, 122, 255, 1);
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
}

.tack {
  opacity: 1;
  color: rgba(58, 58, 60, 1) !important;
  font-family: "Inter-Bold";
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5px;
}

.pointer_none {
  pointer-events: none;
  opacity: .7;

}

.listing_loader {

  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 36px;
  /* height: 100%; */

}

.view:hover {
  text-decoration: none;
}

.all-image {
  position: absolute;
  z-index: 99;
  left: 85px;
  text-decoration: none;
}

.all-image h3 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: #3A3A3C;
  padding-top: 45px;
}

.popular {
  padding: 20px 0px;
}

.col-md-3.pop {
  width: 20%;
}

.popular-title {
  padding-bottom: 20px;
  position: relative;
  align-items: center;
}

.popular-title h2 {

  font-family: 'Heebo', sans-serif;
  position: relative;
}

.popular-title h2::after {
  position: absolute;
  content: '';
  z-index: 999;
  background-color: #224151;
  top: 30px;
  left: -4px;
  right: 0;
  margin: 0px auto;
  width: 87px;
  height: 3px;
  border-radius: 5px;
}

.popular-box {
  position: relative;
  background: #FFFFFF;
  border: 1px solid #D1D1D6;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 10px 29px -15px #787880;
  margin-bottom: 25px;
  height: 275px;
}

.item img {
  max-width: 100% !important;
}

.popular-content i {
  color: #FFA200;
}

.popular-content h3 {
  font-size: 15px;
  font-weight: 500;
  color: #3A3A3C;
  font-family: 'Heebo', sans-serif;
  padding: 6px 0px;
  margin: 0px;
}

.popular-content span {
  font-size: 15px;
  font-weight: 700;
  color: #3A3A3C;
  font-family: 'Heebo', sans-serif;
}

.popular-content small {
  font-size: 13px;
  font-weight: 600;
  color: #c9c4c4;
  font-family: 'Heebo', sans-serif;
  text-decoration: line-through;
}

.popular-content {
  padding-top: 14px;
  position: absolute;
  bottom: 6px;

}

.popular-icon {
  display: inline-grid;
  position: absolute;
  top: 40%;
  right: 17px;
}

.popular-icon i {
  color: #D1D1D6;
  font-size: 20px;
  padding-bottom: 12px;
}

.heart i {
  transition: 0.5s;
}

.popular-box:hover .heart i {
  color: #CB0618;
  cursor: pointer;
  transform: rotate3d(0, 1, 0, 180deg);
}

.wishlist_heart {
  color: #CB0618 !important;
}

.best-selling {
  padding: 20px 0px;
}

.live {
  padding: 20px 0px;
}

.short-fish {
  padding: 20px 0px;
}

.latest {
  padding: 20px 0px;
}


.clients {
  padding: 20px 0px;
}








.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.owl-nav button.owl-prev {
  left: -25px;
}

.owl-nav button.owl-next {
  right: -25px;
}

.owl-dots {
  text-align: center;
  padding-top: 15px;
}

.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}

.owl-dots button.owl-dot.active {
  background-color: #000;
}

.owl-dots button.owl-dot:focus {
  outline: none;
}

.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}

.owl-prev span {
  font-size: 70px;
  position: relative;
  top: -5px;
}

.owl-next span {
  font-size: 70px;
  position: relative;
  top: -5px;
}

.owl-nav button:focus {
  outline: none;
}

.item {
  margin: 10px;
}

.top {
  --offset: 50px;
  position: sticky;
  bottom: 40px;
  margin-right: 10px;
  place-self: end;
  margin-top: calc(100vh + var(--offset));
  text-decoration: none;
  padding: 12px 15px;
  font-family: sans-serif;
  color: #fff !important;
  background-color: #057ed5;
  border-radius: 100px;
  white-space: nowrap;
  left: 15px;
}

.offer {
  padding-bottom: 48px !important;
}

.offer_top {
  padding-top: 48px !important;

}

.active {
  color: #179be9 !important;
}

/* style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff" */
.swiper-button-next {
  color: white;
}

.swiper-button-prev {
  color: white;
}

.category_single {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popular-box-img {
  max-height: 60%;
  margin: auto;
  display: flex;
  justify-content: center;

}

.news_wrapper_mystyle {

  width: 1250px;
  margin: auto;
  padding: 0px 12px 20px;

}

.border-none {
  border: none !important;
}

.product-sub-header {
  padding: 15px 45px;
}

.product-sub-header>.product-sub-header-title {
  text-decoration: none !important;
}

.categorie-item-name {
  font-size: 15px;
  font-weight: 700;
  color: #3A3A3C;
  text-align: center;
  padding-top: 25px;
}

.categorie-slider-img {
  width: 150px !important;
}


.categorie-slider-iteam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.owl-dot {
  display: none !important;
}

.product-list {
  margin: 1% 2% !important;
}

.product-list-product {
  height: 300px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #D1D1D6;
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 25px;
}

.accordion-item {
  text-decoration: none !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  color: #999999 !important;
  /* border: none !important; */
}

.accordion-list-body a {
  border: none !important;
}

.accordion-item-body {
  height: 80px;
  /* overflow: auto; */
  padding: 0px
}

.accordion-list-body {
  list-style: none;
  padding-bottom: 4px;
}

.accordion-list-tag {
  font-size: 13px;
  font-weight: 700;
  color: #999999;
  margin: 0px;


}

.universal_subheader {
  color: black;
  opacity: 25%;
  font-size: 13px;
  font-weight: 700;
}

.slider_border {
  display: flex;
  justify-content: center;
}

.image_fix_size {
  height: 200px !important;
  width: 100%;
  object-fit: cover;
}

@media (max-width:1360px) {
  .image_fix_size {
    height: 150px !important;
  }

  .product-list-product {
    height: unset;
    min-height: 244px;
  }

  .home-demo {
    max-width: 1180px;
    margin: 0 auto;
  }
}

@media (max-width:1280px) {
  .home-demo {
    max-width: 1100px;
    margin: 0 auto;
  }
}

@media (max-width:1199px) {
  .image_fix_size {
    height: 100px !important;
  }

  .product-list-product {
    height: unset;
    min-height: 200px;
  }

  .home-demo {
    max-width: 880px;
    margin: 0 auto;
  }
}

@media (max-width:991px) {

  .home-demo {
    max-width: 685px;
    margin: 0 auto;
  }

  .image_fix_size {
    height: 150px !important;
  }

  .product-list-product {
    height: unset;
    min-height: 244px;
  }

  .fooer-box-img {
    margin-top: 0;
  }
 
}


@media (max-width:767px) {
  .home-demo {
    max-width: 385px;
  }
}

@media (max-width:575px) {
  .home-demo {
    max-width: 385px;
    margin: 20px auto 0;
  }

  .image_fix_size {
    height: 210px !important;
  }
  .breadcum {
    margin-bottom: 10px !important;
  }
}

@media (max-width:450px) {
  .home-demo {
    max-width: 265px;
    margin: 20px auto 0;
  }

  .category_single {
    display: flex;
    width: 100%;
  }

  .category_single .owl-carousel .owl-item img {
    width: 100% !important;
  }
}

.card_main:hover {
  box-shadow: 1px 4px 10px grey;
  -webkit-transition: box-shadow .2s ease-in;
  cursor: pointer;
}

.card_main del {
  font-size: 13px;
  font-weight: 700;
}

.product_sku {
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  margin-top: 6px;
  color: #3C485E;
}

.varients_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  position: relative;
  font-size: 14px;
  height: 25px;
  width: 100%;

}

.disable_varient div {
  position: absolute;
  top: 34%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: red;
  transform-origin: top left;
  transform: rotate(9deg) translateY(-50%);
}

.varients_box:hover {
  box-shadow: 1px 2px 2px grey;
  -webkit-transition: box-shadow .2s ease-in;
}

.disable_varient {
  opacity: .5;
}

.disable_varient:hover {
  cursor: not-allowed;
  box-shadow: none;
}

.active_varient {

  border: 2px solid black;
  font-weight: 800;

}

.active_varient:hover {

  box-shadow: 0 0 0 0 !important;

}

.spiiner_In_details {
  height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}


.products_wrap {
  margin-top: 30px !important;
}

.products_wrap .products_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.products_wrap .products_title_block h3 {
  color: rgba(60, 72, 94, 1);
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.products_wrap .products_title_block p {
  color: rgba(142, 142, 147, 1);
  font-size: 13px;
  font-weight: 700;
  margin-top: 4px;
}

.products_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.products_title_block h3 {
  color: rgba(60, 72, 94, 1);
  font-size: 13px;
  font-weight: 700;
}

.products_title_block p {
  color: rgba(142, 142, 147, 1);
  font-size: 13px;
  font-weight: 700;
  margin: 5px 0 0 0;
}

.products_select {
  display: flex;
  align-items: center;
  gap: 12px;
}

.products_select .grid_ic {
  display: inline-block;
  cursor: pointer;
  width: 34px;
  height: 34px;
  background-color: rgba(34, 65, 81, 1);
  padding: 6px;
}

.products_select .grid_ic svg path {
  fill: #fff;
}


.products_select .list_ic {
  display: inline-block;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border: 1px solid rgba(220, 220, 220, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 6px;
}

.products_select .list_ic svg path {
  fill: rgba(161, 165, 183, 1);
}

.products_select .list_ic.view_active {
  background-color: rgba(34, 65, 81, 1);
}

.products_select .list_ic.view_active svg path {
  fill: #fff;
}

.products_item_img {
  position: relative;
}

.products_item_img p {
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  font-weight: 700;
  background-color: rgba(48, 209, 88, 1);
  padding: 3px 6px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.products_blur_img img {
  filter: blur(3px);
}

.out_of_stock {
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 100%;
  height: 40px;
}

.out_of_stock p {
  background-color: rgba(238, 238, 238, 1);
  color: rgba(255, 59, 48, 1);
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding: 11px;
}

.products_item_img .heart_ic {
  display: inline-block;
  position: absolute;
  bottom: -5px;
  right: 25px;
  width: 30px;
  height: 30px;
  z-index: 1;
}


.card_product_list_block {
  border: 1px solid rgba(209, 209, 214, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.products_item_list {
  list-style: none;
  padding: 0;
}

.products_item_list li {
  width: 100% !important;
  margin-bottom: 15px;
}

.products_item_list li .product_list_img_block {
  width: 30%;
}

.products_item_list li .available_stock {
  width: 10%;
}
.products_item_list li  .list_heart_ic{
  width: 10%;
  text-align: center;
}

.products_item_list li  .list_value {
  width: 10%;
}
.products_item_list li  .list_add_btn {
  width: 20%;
}

.product_list_img_sec {
  position: relative;
  height: 93px !important;
  width: 104px;
}

.product_list_img_sec img {
  height: 100% !important;
}

.product_list_img_sec p {
  width: 100%;
  color: rgba(255, 59, 48, 1);
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(238, 238, 238, 1);
  padding: 4px 10px 2px 10px;
  margin: 0;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.product_list_img_sec span {
  display: inline-block;
  background-color: rgba(48, 209, 88, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 9.558824px;
  font-weight: 700;
  padding: 4px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.product_list_conte h3 {
  color: rgba(0, 122, 255, 1);
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0px !important;
}

.product_list_conte p {
  margin: 5px 0 0 0;
  color: rgba(60, 72, 94, 1);
  font-size: 13px;
  font-weight: 700;
}

.product_list_img_block {
  display: flex;
  align-items: center;
}

.product_list_img_blur img {
  filter: blur(3px);
}

.product_list_conte {
  margin-left: 25px;
}

.available_stock span {
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 500;
}

.available_stock p {
  color: rgba(48, 209, 88, 1);
  font-size: 13px;
  font-weight: 500;

}

.list_heart_ic span {
  display: inline-block;
  width: 18px;
  height: 16px;
}

.list_value p {
  color: rgba(36, 114, 240, 1);
  font-size: 18px;
  font-weight: 500;
}

.list_value p span {
  font-size: 13px;
}

.list_value .product_price {
color: rgba(36,114,240,1);
font-size: 18px;
font-weight: 700;
}

.list_value .product_price del {
color: rgba(0,0,0,0.25);
font-size: 13px;
font-weight: 700;
}

.list_add_btn {
  padding-right: 30px;
}

.list_right_border {
  position: relative;
}

.list_right_border::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
  width: 2px;
  height: 53px;
  background-color: rgba(120, 120, 128, 0.2);
}

.card_product_list_block+.card_product_list_block {
  margin-top: 10px;
}


.products_select select {
  border: 1px solid rgba(220, 220, 220, 1);
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 500;
  padding: 9px 15px;
  outline: none;
  width: 200px;
}

.products_wrap .products_item_block {
  margin-top: 15px;
}

.products_wrap .products_item {
  list-style: none;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin: -8px;
  padding: 0;
}

.products_wrap .products_item li {
  width: 20%;
  padding: 8px;
}
.products_item_4 .products_item li{
  width: 25%;
}

.products_wrap .products_item_sec {
  border: 1px solid rgba(209, 209, 214, 1);
  background-color: rgba(255, 255, 255, 1);
  min-height: 428px;
  overflow: hidden;
}

.products_item_sec img {
  width: 100%;
  height: 200px !important;
  object-fit: cover;
}

.products_wrap .products_value_block {
  padding: 8px 15px;
}

.products_wrap .products_value_block .product_price {
  color: #2472F0;
  font-weight: 700;
  margin-top: 5px;
}

.products_wrap .products_value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  opacity: 1;
  color: rgba(60, 72, 94, 1) !important;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 5px;
}

.product_upc {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.product_upc,
.product_upc span {
  color: rgba(153, 153, 153, 1) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.products_wrap .products_value p {
  margin: 0px;
  margin-top: 0px;
  height: 35px;
  overflow: hidden;
  color: rgba(60, 72, 94, 1);
  font-size: 13px;
  font-weight: 700;
}

.products_wrap .products_value h3 {
  color: rgba(36, 114, 240, 1);
  font-size: 15px;
  font-weight: 600;
}

.products_wrap .products_value h3 del {
  color: rgba(0, 0, 0, 0.25);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
}

.products_wrap .products_value_block .fishing_p {
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.products_wrap .products_value_block h4 {
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 500;
  margin-top: 7px;
}

.products_wrap .products_value_block h4 span {
  color: rgba(142, 142, 147, 1);
  font-size: 15px;
  font-weight: 500;
}

.products_wrap .add_to_cart_btn {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.products_wrap .add_to_cart_input input {
  border: 1px solid rgba(209, 209, 214, 1);
  background-color: rgba(255, 255, 255, 1);
  outline: none;
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 500;
  padding: 9px 11px;
  width: 50px;
  margin-right: 10px;
}

.products_wrap .add_to_cart_btn a,
.add_to_cart_btn button {
  display: flex;
  align-items: center;
  background-color: rgba(34, 65, 81, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 500;
  padding: 9px 15px;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.products_wrap .add_to_cart_btn a:hover,
.add_to_cart_btn button:hover {
  color: #224151;
  border: 1px solid #224151;
  background: #fff;
}

.products_wrap .add_to_cart_btn svg {
  margin-right: 10px;
}



.products_wrap .add_to_cart_btn button:hover svg path{
  fill: #224151;
}

.add_to_cart_btn .btn_sec {
  width: 100%;
}

.product-list .price_range_search input {
  border-radius: 4px;
  border: 1px solid rgba(209, 209, 214, 1);
  background-color: rgba(255, 255, 255, 1);
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 500;
  padding: 9px 15px;
  outline: none;
  width: 100%;
  margin-bottom: 15px;
}

.product-list .price_range p {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.product-list .price_range_min {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
}

.product-list .price_range_min>div {
  padding: 0 10px;
  width: 50%;
}

.product-list .price_range_min input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(209, 209, 214, 1);
  background-color: rgba(255, 255, 255, 1);
  outline: none;
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  font-weight: 400;
  padding: 9px 15px;
  margin-bottom: 15px;
}


.form_group {
  display: block;
}

.form_group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form_group label {
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
}

.form_group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  border-radius: 1px;
  border: 1px solid rgba(151, 151, 151, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form_group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid RGB(51, 176, 4);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.accordion-item-body {
  height: 100%;
}

.listing {
  border: 0.5px solid rgba(153, 153, 153, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 15px;
  margin-top: 50px;
}

.accordion-item h2 {
  margin-bottom: 0 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  padding: 12px 16px !important;
}

.apply_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -10px;
}

.apply_btn>div {
  width: 50%;
  padding: 0 10px;
}

.apply_btn .apply {
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 500;
  background-color: rgba(34, 65, 81, 1);
  padding: 9px 24px;
  margin-top: 35px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.apply_btn button:hover {
  color: rgba(34, 65, 81, 1);
  border: 1px solid rgba(220,220,220, 1);
  background: #fff;
}
.apply_btn .clear{
  display: block;
  text-align: center;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid rgba(220,220,220, 1);
  background-color: rgba(255,255,255, 1);
  padding: 9px 24px;
  margin-top: 35px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.apply_btn .clear:hover{
  background-color: rgba(34, 65, 81, 1);
  color: rgba(255, 255, 255, 1);
}
.product_list_block {
  display: flex;
  margin: -10px;
}

.product_list_sec {
  width: 20%;
  padding: 10px;
}

.product_list_wrap {
  width: 80%;
  padding: 10px;
}

.main_listing_header {
  background-color: rgba(248, 248, 248, 1);
  /* padding: 20px 43px; */
}

@media (max-width: 1800px) {
  .list_right_border::after {
    right: -25px;
  }
}

@media (max-width: 1600px) {
  .list_right_border::after {
    right: -23px;
  }
  .products_item_list li  .list_add_btn {
      width: 22%;
  }
}

@media (max-width: 1440px) {
  .product_list_sec {
    width: 21%;
  }

  .products_item li {
    width: 33.33% !important;
  }

  .list_right_border::after {
    right: -15px;
  }
  .products_item_list li  .list_add_btn {
      width: 23%;
  }
  .products_item_list li .available_stock {
    width: 12%;
}

}

@media (max-width: 1360px) {
  .product_list_sec {
    width: 24%;
  }

  .products_item_sec {
    min-height: 395px;
  }

  .list_right_border::after {
    right: -8px;
  }

  .products_item_list li .available_stock {
    width: 15%;
  }
  .products_item_list li  .list_add_btn {
      width: 27%;
  }
}

@media (max-width: 1280px) {
  .list_right_border::after {
    right: -14px;
  }
}

@media (max-width: 1199px) {
  .product_list_sec {
    width: 36%;
  }

  .products_item li {
    width: 50% !important;
  }

  .products_item_sec {
    min-height: 345px;
  }

  .list_right_border::after {
    display: none;
  }

  .card_product_list {
    overflow: auto;
  }

  .card_product_list_block {
    width: 140%;
  }

  .products_item_list li {
    width: 100% !important;
  }

  .list_add_btn {
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .product_list_sec {
    width: 52%;
  }

  .products_item li {
    width: 100% !important;
  }

  .products_item_list li {
    width: 87% !important;
  }

  .card_product_list_block {
    width: 188%;
  }


}

@media (min-width: 767px) {

  .filter_position {
    position: sticky !important;
    top: 12px;
  }


}

@media (max-width: 767px) {
  .product_list_block {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .product_list_sec {
    width: 100%;
  }

  .product_list_wrap {
    width: 100%;
  }

  .product {
    padding: 10px 20px;
  }
  .product_details_main{
    padding: 0;
  }
  .product_details_main .inner_breadcum{

  }
  

  .products_item_list li {
    width: 100% !important;
    margin-bottom: 0;
  }

  .card_product_list_block {
    width: 157%;
  }
}

@media (max-width: 575px) {
  .products_title {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .products_title_select {
    margin-top: 10px;
  }

  .card_product_list_block {
    width: 227%;
  }
  .products_item_list li {
      width: 120% !important;
      margin-bottom: 0;
  }
  
}

@media (max-width: 367px) {
  .card_product_list_block {
    width: 267%;
  }
}

.active_view_all {
  border: 1px solid black !important;
}

.product_mpn {
  color: rgba(36, 114, 240, 1) !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  margin: 15px 0 0 0 !important;
  height: unset !important;
}

/* Updated Css here */

.quick_access_global {
  width: 100vw;
  background-color: #F8F8F8;
}

.quick_access_global span,
span a {
  color: #ddd !important;
}

.quick_access_global div {

  max-width: 92%;
  margin: auto;
}

.quick_access_global .login-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.quick_access_global .login_navigate a,
.quick_access_global .login_navigate {
  opacity: 1;
  color: rgba(0, 0, 0, 0.25);
  /* font-family: "Inter-Bold"; */
  font-size: 13px !important;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  text-transform: uppercase;
}

.Toastify__toast-container--top-center {
  z-index: 100000 !important;
}

.geosuggest__suggests--hidden {
  max-height: 0 !important;
  overflow: hidden;
  border-width: 0;

}

.geosuggest_absolute {
  position: absolute;
  background: white !important;
  width: 100%;
  z-index: 1000;
  /* border: 1px solid black; */
  /* border-radius: 10px; */
  /* padding: 20px; */
  /* padding: 10px; */
  /* height: 100px; */
}

.confirm_btn {
  background: #224151;
  --bs-btn-bg: #224151;
}

.pop {
  cursor: pointer;
}


.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: block !important;
}

.owl-theme .owl-nav [class*='owl-']:hover,
.owl-theme .owl-next [class*='owl-']:hover {
  background: #869791;
  color: #869791;
  text-decoration: none;
}

.owl-prev span,
.owl-next span {
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: unset;
}

.owl-theme .owl-nav [class*='owl-'] {
  margin: 0;
}

.owl-nav button.owl-prev {
  left: -45px;
  top: 40%;
  transform: translateY(-50%);
}

.owl-nav button.owl-next {
  right: -45px;
  top: 40%;
  transform: translateY(-50%);
}

.breadcum {
  background: #F8F8F8;
  /* width: 100vw; */
  margin-bottom: 50px;
}

.inner_breadcum {
  display: flex;
  justify-content: space-between;
  padding: 20px 11px;
  margin-bottom: 30px;
  max-width: 92%;
  margin: auto;
}

.breadcum .inner_breadcum .main_title {
  color: rgba(60, 72, 94, 1);
  font-size: 13px !important;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
}



.breadcum .universal_subheader a {
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
}

.profile_main .title {
  color: rgba(28, 28, 30, 1);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}

.profile_main label {
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 25px;
}

.profile_main input {
  border: 1px solid rgba(220, 220, 220, 1);
  border-radius: 0;
  padding: 13px 20px;
  color: rgba(60, 72, 94, 1);
  font-size: 15px;
  font-weight: 700;
  background: #fff !important;
}
.profile_main input::placeholder{
  color: rgba(153,153,153,1) !important;
}


.profile_toggle input[type="checkbox"] {
  position: relative;
  width: 35px;
  height: 21px;
  -webkit-appearance: none;
  appearance: none;
  background: red !important;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  margin: 0 0 -5px 0;
  padding: 0;
}

.profile_toggle input[type="checkbox"]::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.profile_toggle input[type="checkbox"]:checked::before {
  transform: translateX(80%);
  background: #fff;
}

.profile_toggle input[type="checkbox"]:checked {
  background: #00ed64 !important;
}

.fooer-box-img img {
  width: 100%;
}

.availableStock {
  color: rgba(48, 209, 88, 1) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.price_range_min .multi-range-slider {
  width: 100% !important;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* .header_layer{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0000009a;
} */

.header_active .header_layer1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease;
  opacity: 0;
  z-index: 11;
  visibility: hidden;
}

.header_layer1 {
  opacity: 1;
  visibility: visible;
}

.header_block{
  display: none !important;
}
.show.header_block{
  display: block !important;
}
